.tree-container {
    /*height: calc(100vh - 88px);*/
    height: 100%;
}
.title-management {
    padding: 10px 0;
}
.ant-switch.mr-3 {
    margin-right: 10px;
}

.tree-container .ant-select-selector {
    align-items: center;
}
.tree-container .ant-select-selection-search {
    display: flex;
    align-items: center;
}
.item,
.item.ant-select {
    margin-bottom: 5px;
}
.ant-input.item {
    margin-bottom: 10px;
}
.item.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 7px;
}
.tree-container .ant-input-affix-wrapper {
    height: 100%;
}
.without-margin.ant-form-item {
    margin-bottom: 0;
}
