.Success div.ant-typography {
    text-align: start;
}
.Success .ant-form-item-control-input button {
    border-color: #209f38;
    background-color: #209f38;
}
.Success .ant-btn-background-ghost.ant-btn-primary,
.Success .ant-btn-background-ghost.ant-btn-primary:active,
.Success .ant-btn-background-ghost.ant-btn-primary:hover,
.Success .ant-btn-background-ghost.ant-btn-primary:focus {
    border-color: #209f38;
    color: #209f38;
}
.Success .margin-top-20 {
    margin-top: 20px;
}
.Success .margin-bottom-20 {
    margin-bottom: 20px;
}
.Success button {
    min-width: 300px;
}
.Success .flex-column .height-without-header img.processing {
    /*margin: 0 calc(50% - 20px);*/
}
.Success img.processing.alert {
    margin: 0 calc(50% - 76px);
}
.Success img.processing {
    /*margin: 0 calc(50% - 76px);*/
    margin: 0 calc(50% - 110px);
}
.Success img.check-mark {
    margin: 0 calc(50% - 76px);
}
.Success img.pencil {
    /*margin: 0 calc(50% - 29px);*/
    margin: 0 calc(50% - 110px);
}
.Success img.alert {
    margin: 0 calc(50% + 6px);
}
.checking .Success .flex-column {
    flex-direction: initial;
}

.Success .flex-column {
    flex-direction: column;
    min-height: fit-content;
    /*height: calc(100vh - 75px);*/
    height: 100%;
}

.Success .height-without-header {
    /*height: calc(100vh - 75px);*/
    height: 100%;
    width: 100%;
}

@media (min-width: 750px) {
    .Success .flex-column .ant-col {
        width: 100%;
    }
}
