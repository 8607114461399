.PolicyAgreement .ant-page-header-heading-title {
    font-weight: normal;
    color: #209f38;
    white-space: normal;
    text-align: start;
}

.PolicyAgreement h4.ant-typography {
    text-align: start;
    margin: -20px 0 5px;
    font-size: 16px;
}

.PolicyAgreement .document {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 3px;
    white-space: pre-line;
    text-align: start;
    margin: 0 20px 20px 20px;
    width: 100%;
}
