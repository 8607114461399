/*@font-face {*/
/*  font-family: "SF Pro Text";*/
/*  src: url("./img/SFProText-Regular.ttf") format("truetype");*/
/*  font-style: normal;*/
/*  font-weight:  normal;*/
/*}*/
body {
  margin: 0;
  font-family: 'SF Pro Text';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
