.myTaxApp {
    /*margin-bottom: 3rem;*/
    height: 100%;
}

.myTaxApp h4, .myTaxApp p {
    text-align: left;
}

.myTaxApp h4 {
    margin-top: 2rem !important;
}

.myTaxApp ul {
    list-style: decimal;
    padding-left: 25px;
    text-align: left;
}

.myTaxAlert h5 ,
.myTaxAlert ul li,
.myTaxApp ul li {
    text-align: left;
}

.myTaxAlert ul {
    padding-left: 1rem;
}

.myTaxAlert {
    height: calc(100vh - 120px);
    /*height: 100%; action=editEmploymentType */
    align-content: space-between;
}

/*.myTaxAlert div {*/
/*    text-align: center;*/
/*}*/

.myTaxAlert div button {
    padding: 6px 16px !important;
}
.ant-btn.btn-my-tax:hover,
.ant-btn.btn-my-tax:active,
.ant-btn.btn-my-tax:focus,
.ant-btn.btn-my-tax {
    background-color: #0066B3;
    color: #FFFFFF;
}
/*.myTaxApp .selfEmployedStatus {*/
/*    height: 100%;*/
/*}*/
.myTax-error .btn-block {
    height: fit-content;
}
.myTax-error {
    /*height: calc(100vh - 45px);*/
    height: 100%;
    align-content: space-between;
}
.Success .myTax-error img.alert {
    margin: 0 calc(50% - 68px);
}
