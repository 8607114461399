.left-menu.ant-menu-inline .ant-menu-item {
    margin: 0;
}
.left-menu.ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
}
.left-menu .ant-menu-item {
    font-size: 18px;
}
.AdminLayout .ant-menu-light .ant-menu-item:hover {
    color: white;
}
