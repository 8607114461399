.ProfileWrapper .steps-content .selfEmployedStatus {
    /*height: calc(100% - 21px);*/
    height: 100%;
}
.selfEmployedStatus {
    /*height: calc(100vh - 21px);*/
    height: 100%;
}
.selfEmployedStatus .btn-my-tax {
    /*height: calc(100vh - 21px);*/
    margin-top: 3px;
}
.selfEmployedStatus .info-block {
    /*height: calc(100% - 121px);*/
    /*overflow-y: auto;*/
    height: calc(100% - 123px);
    overflow-y: auto;
    display: flex;
}
.selfEmployedStatus h4,
.selfEmployedStatus p {
    text-align: left;
}
