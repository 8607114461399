.Offers .ant-btn-link {
    padding: 0;
    height: auto;
}
.Offers-modal .ant-table .ant-table-cell {
    word-break: break-word;
    white-space: pre-line;
    font-size: 12px;
}
.Offers-modal .ant-typography code {
    display: inline-block;
}
