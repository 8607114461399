.tax-service-step h3 {
    text-align: left;
    padding-top: 16px;
}

.tax-service-step ul{
    /*font-size: 16px;*/
    /*line-height: 20px;*/
    display: block;
    text-align: left;
    padding-left: 20px;
}

.tax-service-step .ant-btn {
    width: 100%;
    margin-top: 1rem;
    height: 40px;
    font-size: 16px;
}
.tax-service-step .ant-btn img {
    margin-right: 0.5rem;
}

.tax-service-step .ant-btn-success {
    color: var(--green-color);
    color: #FFFFFF;
}

.tax-service-step > img.processing {
    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    margin: 50%;
    transform: translate(-50%, -50%);
}

.tax-service-step .ant-btn.btn-my-tax:hover,
.tax-service-step .ant-btn.btn-my-tax:active,
.tax-service-step .ant-btn.btn-my-tax:focus,
.tax-service-step .ant-btn.btn-my-tax {
    background-color: #0066B3;
    color: #FFFFFF;
}

.flex-column {
    /*height: calc(100vh - 30px);*/
    height: 100%;
    align-content: space-between;
}
.tax-service-step,
.TaxService {
    /*height: calc(100vh - 21px);*/
    height: 100%;
}
.TaxService .later-btn {
    margin-top:  0;
}
.TaxService .btn-block {
    /*height: calc(100vh - 21px);*/
    margin-top: 3px;
    padding-bottom: 20px;
}
.TaxService .info-block {
    /*height: calc(100% - 121px);*/
    /*overflow-y: auto;*/
    height: calc(100% - 174px);
    overflow-y: auto;
    display: flex;
}
