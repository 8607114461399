.Act {
    /*padding: 0 20px;*/
}
.Act .title {
    padding-top: 0;
}
.Act .ant-page-header-heading-title {
    white-space: break-spaces;
}
.Act .title .ant-page-header-heading-title {
    font-weight: normal;
    color: #209f38;
    white-space: normal;
    text-align: start;
}

.Act .ant-form-item-control-input button {
    border-color: #209f38;
    background-color: #209f38;
}

.Act .continue-button.ant-btn-lg {
    height: 55px;
    font-size: 24px;
}
.Act .ant-checkbox {
    margin-left: -5px;
}

.Act div.ant-typography {
    text-align: start;
    margin-bottom: 20px;
}
.Act h4.ant-typography {
    text-align: start;
    margin: -20px 24px 25px;
    font-size: 16px;
    font-weight: 400;
}

.Act .document {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 3px;
    white-space: pre-line;
    text-align: start;
}
.Act .ant-checkbox-wrapper {
    padding-left: 25px;
    text-align: start;
    line-height: 24px;
    padding-top: 20px;
}
