.Auth .ant-layout-content {
    background-color: #f7f8fa;
    padding-top: 51px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.Auth {
    padding: 28px 20px;
    text-align: center;
}
.mobile-number-btn {
    /*margin-bottom: 36px;*/
}
.App .Auth .ant-layout {
    min-height: calc(100vh - 161px);
}
.Auth .ant-typography {
    text-align: center;
}
.Auth .ant-row.ant-form-item {
    margin-bottom: 15px;
}

.Auth h4.ant-typography {
    margin-bottom: 30px;
    font-size: 16px;
    /*font-weight: normal;*/
}

.Auth .ant-form-item-control-input input,
.Auth .ant-form-item-control-input button {
    border-radius: 2px;
}

.Auth .ant-btn-primary:active,
.Auth .ant-btn-primary:hover,
.Auth .ant-btn-primary:focus,
.Auth .ant-btn-primary {
    background-color: #209f38;
}

.Auth .ant-input:hover,
.Auth .ant-input:focus,
.Auth .ant-input-focused,
.Auth .ant-btn-primary,
.Auth .ant-form-item-control-input input,
.Auth .ant-form-item-control-input button,
.Auth .ant-btn-primary:active,
.Auth .ant-btn-primary:focus,
.Auth .ant-btn-primary:hover {
    border-color: #209f38;
}
.Auth .ant-input[disabled],
.Auth .ant-btn[disabled] {
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
}
.Auth .ant-form-item-label {
    text-align: left;
}
.Auth .ant-image {
    z-index: 1;
}
/*@media (max-width: 575px) {*/
/*    .Auth .ant-form .ant-form-item .ant-form-item-label,*/
/*    .Auth .ant-form .ant-form-item .ant-form-item-control,*/
/*    .Auth .ant-form .ant-form-item .ant-form-item-control.ant-col-xs-18 {*/
/*        flex: 0 0 75%;*/
/*        max-width: 75%;*/
/*    }*/
/*}*/

.sberId img {
    margin-right: 0.5rem;
}

.Profile button.sberId {
    border-radius: 2px;
}

.transparent-btn.ant-btn-primary.ant-btn:not([disabled]):active,
.transparent-btn.ant-btn-primary.ant-btn:hover,
.transparent-btn.ant-btn-primary.ant-btn:focus,
.transparent-btn.ant-btn-primary,
.Auth .withSberId,
.Auth .withSberId:hover,
.Auth .withSberId:focus {
    background-color: #E6F7FF !important;
    border-color: #1890FF !important;
    color: #1890FF;
    text-shadow: none;
    box-shadow: none;
}

.tinkoffId {
    background-color: #FFDD2D !important;
    border-color: #FFDD2D !important;
    color: #262626 !important;
}

.tinkoffId img {
    height: 20px;
    margin-left: 0.5rem;
}

.tid-4PNRE-button-primary {
    width: 100%;
}
.tid-4PNRE-button-primary, .tid-4PNRE-text-primary {
    font-size: 16px !important;
    color: #262626 !important;
}
.tid-4PNRE-button-primary:hover {
    background-color: #FFDD2D !important;
}

.tid-4PNRE-button .tid-4PNRE-text {
    font-family: 'SF Pro Text';
}

.etcBlock {
    margin-bottom: 20px;
    margin-top: 20px;
}

.modalAuthPhone .message {
    text-align: center;
    color: #838383;
    margin-top: 8px;
}

.modalAuthPhone button {
    margin-top: 1rem;
}

.modalAuthPhone.mobileDrawerModal {
    min-height: 100%;
}

.modalAuthPhone.hide.mobileDrawerModal {
   z-index: -1;
}
.modalAuthPhone.mobileDrawerModal .ant-drawer-content-wrapper{
   min-height: 178px;
}
.input-sms {
    margin-bottom: 40px;
}
.input-sms-hide {
    position: absolute;
    opacity: 0;
    bottom: 10px;
    z-index: -1;
}

.ant-form-item.phone-btn {
    margin-bottom: 30px;
}
.ant-form-item.phone-input {
    margin-bottom: 0;
}
