.photo-viewer {
    position: fixed;
    width: 40vw;
    height: 70vh;
    border: 1px solid #D3D3D3;
    padding: 0.5rem;
}

.photo-viewer .toolbar {
    margin-bottom: 0.5rem;
}

.photo-viewer .image-wrap {
    position: relative;
    /*border: 1px solid red;*/
    width: 100%;
    height: 37vh;
    overflow: hidden;
}

.photo-viewer .image-wrap img {
    position: absolute;
    max-width: 700px;
    max-height: 500px;
}

.photo-viewer .scroll-container {
    height: 50vh;
    overflow: hidden;
}