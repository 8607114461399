.ant-table-row {
    cursor: pointer;
}
.with-copy-icon.with-border .anticon-copy {
    height: 140px;
    border-right: 1px solid lightgray;
    padding-right: 20px;
    align-items: center;
    display: flex;
}
.with-copy-icon {
    position: relative;
}
.with-copy-icon .anticon-copy {
    position: absolute;
    top: 4px;
    padding: 4px;
    z-index: 1;
}
.phone {
    display: inline-block;
    margin-top: 5px;
}
.ant-form-item.middle-margin-bottom {
    margin-bottom: 6px;
}
.vertical-align-sub {
    vertical-align: sub;
}
.table-settings {
    font-size: 20px;
    color: lightgray !important;
    cursor: pointer;
    padding: 10px;
}
.table-settings.modified {
    color: #1890ff !important;
}
.settings .ant-checkbox-wrapper {
    display: inline-flex;
    width: 100%;
}
.ant-divider-horizontal {
    margin: 15px 0;
}

.partners-actions {
    height: 40px;
    margin-bottom: 5px;
}
.partners-actions .ant-col {
    display: flex;
    align-items: center;
}

.partners-actions .ant-btn {
    margin-right: 1rem;
}