:root {
  --green-color: #199F67;
}
#root {
  /*height: fit-content;*/
  height: 100%;
}

.App {
  font-family: 'SF Pro Text';
  height: 100%;
}

.height-inherit,
.App .ant-form {
  height: inherit;
  min-height: 100%;
}

.App .ant-spin-container,
.App .ant-spin-nested-loading {
  height: 100%;
  min-height: 100%;
}

.App .AdminLayout input,
.App .AdminLayout button,
.App .AdminLayout select,
.App .AdminLayout optgroup,
.App .AdminLayout textarea,
.ant-drawer.AdminDrawer,
.App .AdminLayout {
  font-family: 'TT Norms','Helvetica Neue',Helvetica,Arial,sans-serif;
}

.ant-drawer,
.App input,
.App button,
.App select,
.App optgroup,
.App textarea {
  font-family: 'SF Pro Text';
}

.App-link {
  color: #61dafb;
}

.App .ant-layout {
  background-color: #f7f8fa;
  height: 100%;
  min-height: 100%;
  overflow-y: auto;
}
.ant-btn.ant-btn-success {
  color: #fff;
  background: var(--green-color);
  border-color: var(--green-color);
}
.ant-btn.ant-btn-success:active,
.ant-btn.ant-btn-success:focus,
.ant-btn.ant-btn-success:hover {
  background: var(--green-color);
  border-color: var(--green-color);
}
.ant-modal-wrap .ant-btn > .ant-btn-loading-icon .anticon,
.ant-drawer .ant-btn > .ant-btn-loading-icon .anticon,
.App .ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
}
.ant-modal-wrap .ant-btn > .ant-btn-loading-icon,
.ant-drawer .ant-btn > .ant-btn-loading-icon,
.App .ant-btn > .ant-btn-loading-icon {
  padding-right: 8px;
}
.mt-4 {
  margin-top: 30px;
}
.mt-2 {
  margin-top: 5px;
}
.mt-3 {
  margin-top: 10px;
}
.ml-2 {
  margin-left: 5px;
}
.ml-3 {
  margin-left: 10px;
}
.mr-3 {
  margin-right: 10px;
}
.mb-3 {
  margin-bottom: 10px;
}
.hide.ant-btn,
.hide {
  display: none;
}
.text-align-left {
  text-align: left;
}
.break-word {
  word-wrap: break-word;
  word-break: break-word;
}
.d-flex {
  display: flex;
}

.CheckingData.flex-space-between {
  /*height: calc(100vh - 21px);*/
  height: 100%;
}
.CheckingData .info span.ant-typography{
  display: block;
  text-align: left;
}

.w-150 {
  width: 150px;
}
.w-100 {
  width: 100%;
}
a[disabled] {
  pointer-events: none;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 0;
}
.ant-divider-horizontal.ant-divider-with-text-left .ant-divider-inner-text {
  padding-left: 0;
  color: #209f38;
}

/* запретить показ браузером стрелок верх-низ */
input[type="number"].noScrollNumber::-webkit-outer-spin-button,
input[type="number"].noScrollNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"].noScrollNumber {
  -moz-appearance: textfield;
}
input[type="number"].noScrollNumber:hover,
input[type="number"].noScrollNumber:focus {
  -moz-appearance: number-input;
}
input[type=number].noScrollNumber::-webkit-inner-spin-button,
input[type=number].noScrollNumber::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-message {
  z-index: 3000;
}

.wrapAble div {
  flex-wrap: wrap;
}

.wrapCheckIcons {
  position: relative;
  right: 48px;
  text-align: right;
  width: 70px;
}

.wrapCheckIcons svg {
  margin-left: 3px;
}

.error, .error:hover {
  border-color: #ff4d4f !important;
}

.mobileDrawerModal .ant-drawer-content {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.full-width {
  width: 100vw;
}
.flex-space-between {
  /*height: calc(100vh - 35px);*/
  height: 100%;
  align-content: space-between;
}

.flex-space-between.form {
  height: calc(100% - 85px);
  /*height: 100%;*/
  align-content: space-between;
}

.margin-top-20 {
  margin-top: 20px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}

@media (min-width: 750px) {
  .App .Auth,
  .App .ProfileContractScreen,
  .App .ProfileFormWrapper,
  .App .PolicyAgreement,
  .App .PhotoDocHint,
  .App .ProfileWrapper {
    max-width: 750px;
    margin: 0 auto;
  }
}
