.success-step h3 {
    text-align: left;
    padding-top: 16px;
}
.success-step span.ant-typography {
    /*font-size: 16px;*/
    /*line-height: 20px;*/
    display: block;
    text-align: left;
}

.success-step > img {
    margin-top: 50px;
}

.success-step .ant-btn {
    width: 100%;
    margin-bottom: 20px;
    height: 40px;
    font-size: 16px;
}

.success-step .ant-btn-success {
    color: var(--green-color);
    color: #FFFFFF;
}
.success-step .flex-column {
    /*flex-direction: column;*/
    /*height: calc(100vh - 34px);*/
    height: 100%;
}
