.docWithNotices .ant-alert {
    align-items: start;
}

.docWithNotices .ant-alert-close-icon {
    margin-top: 5px;
}

.docWithNotices .ant-alert-icon {
    margin-top: 5px;
}

.docWithNotices .ant-alert-success {
    margin-top: 0.5rem;
    border-radius: 10px;
}