.error-step {
    align-content: space-between;
    /*height: calc(100vh - 40px);*/
    height: 100%;
}
.error-step h3 {
    text-align: left;
    padding-top: 16px;
}

.error-step  span.ant-typography {
    font-size: 16px;
    line-height: 20px;
    display: block;
    text-align: left;
}

.error-step .error-step-section-list {
    list-style-type: none;
}

.error-step .error-step-section-list li {
    margin-left: 0;
    padding-left: 0;
}

.error-step .ant-btn {
    width: 100%;
    margin-top: 1rem;
    height: 40px;
    font-size: 16px;
}

.correct-info {
    margin-bottom: 2rem !important;
}
.correct-info ul {
    padding-left: 0;
}
