.modal-upload-photo {
    text-align: center;
}

.modal-upload-photo button {
    border-radius: 7px;
    margin-top: 1rem;
}

.modal-upload-photo .ant-btn, .modal-upload-photo .ant-btn:hover {
    background-color: #FFC53D;
    border-color: #FFC53D;
    color: #262626 !important;
}

.modal-upload-photo .ant-btn-background-ghost, .modal-upload-photo .ant-btn-background-ghost:hover {
    background-color: inherit;
    border-color: #D9D9D9 !important;
    color: #262626 !important;
}