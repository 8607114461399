.App .PolicyScreen {
    height: inherit;
}

.PolicyScreen .ant-page-header-ghost {
    padding-left: 0;
}
.PolicyScreen .ant-page-header-heading-title {
    font-weight: normal;
    color: #209f38;
    white-space: normal;
    text-align: start;
}

.PolicyScreen .ant-form-item-control-input input,
.PolicyScreen .ant-form-item-control-input button {
    /*border-radius: 7px;*/
}

.PolicyScreen .ant-btn-primary,
.PolicyScreen .ant-form-item-control-input button,
.PolicyScreen .ant-btn-primary:active,
.PolicyScreen .ant-btn-primary:hover {
    border-color: var(--green-color);
    background-color: var(--green-color);
}

.PolicyScreen .flex-column {
    flex-direction: column;
    /*height: calc(100vh - 10px);*/
    height: 100%;
}

.PolicyScreen div.ant-typography {
    text-align: start;
    margin-bottom: 0;
}
.PolicyScreen h4.ant-typography {
    text-align: start;
    margin: -20px 0 5px;
    font-size: 16px;
}
.PolicyScreen .ant-checkbox-wrapper {
    padding-left: 25px;
    text-align: start;
    line-height: 24px;
}
.PolicyScreen .ant-checkbox {
    margin-left: -25px;
}
.PolicyScreen .ant-form-item-explain.ant-form-item-explain-error {
    padding: 0 0 10px 25px;
}
.PolicyScreen .align-start {
    align-items: start;
}
.PolicyScreen .document {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 3px;
    white-space: pre-line;
}
@media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-control.ant-col-xs-18 {
        flex: 0 0 75%;
        max-width: 75%;
    }
}

.PolicyScreenV2 .ant-page-header-heading-title {
    font-family: "SF Pro Text";
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.PolicyScreenV2 .ant-form-item-control-input-content {
    font-family: "SF Pro Text";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.PolicyScreenV2 .ant-form-item-control-input button {
    border-radius: 2px;
    border-color: var(--green-color);
    background-color: var(--green-color);
    margin-top: 1rem;
    height: 40px !important;
}
.PolicyScreenV2 .ant-form-item-control-input button:disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
}

.PolicyScreenV2 .ant-btn {
    font-family: "SF Pro Text";
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px;
}

.PolicyScreenV2 .align-start .ant-form-item-control-input-content {
    flex: none;
}

.PolicyScreenV2 a {
    color: var(--green-color);
}

.PolicyScreenV2 .citizenship {
    margin-top: 2rem;
    margin-bottom: 0;
}

.PolicyScreenV2 .citizenship label {
    font-weight: 600;
}

.PolicyScreenV2 .citizenship .ant-select-selection-placeholder, .PolicyScreenV2 .citizenship .ant-select-selection-item {
    text-align: left;
}

.PolicyScreenV2 .ant-form-item-required::before {
    display: none !important;
}
.PolicyScreenV2 .flex-column {
    /*height: calc(100vh - 12px);*/
    height: 100%;
    align-content: space-between;
}

.PolicyScreenV2 .flex-space-between .ant-form-item {
    margin-bottom: 0;
}
