/*
* Last item - is continue button
*/
.Offer .ant-form .ant-form-item:last-child {
    padding-top: 30px;
}

.Offer .ant-page-header-ghost {
    padding-left: 0;
}
.Offer .ant-page-header-heading-title {
    font-weight: normal;
    color: #209f38;
    white-space: normal;
    text-align: start;
}

.Offer .ant-form-item-control-input input,
.Offer .ant-form-item-control-input button {
    /*border-radius: 7px;*/
}

.Offer .ant-btn-primary,
.Offer .ant-form-item-control-input button,
.Offer .ant-btn-primary:active,
.Offer .ant-btn-primary:focus,
.Offer .ant-btn-primary:hover {
    border-color: #209f38;
    background-color: #209f38;
}

.Offer .continue-button.ant-btn-lg {
    /*height: 55px;*/
    /*font-size: 24px;*/
}

.Offer div.ant-typography {
    text-align: start;
    margin: 0;
}
.Offer h4.ant-typography {
    text-align: start;
    margin: -20px 0 5px;
    font-size: 16px;
}
.Offer .ant-checkbox-wrapper {
    padding-left: 25px;
    text-align: start;
    line-height: 24px;
    padding-top: 20px;
}
.Offer .ant-checkbox {
    margin-left: -25px;
}
.Offer .document {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 3px;
    white-space: pre-line;
}
@media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-control.ant-col-xs-18 {
        flex: 0 0 75%;
        max-width: 75%;
    }
}
