.ModalMyTaxApp span.ant-typography {
    font-size: 16px;
    line-height: 20px;
    display: block;
    text-align: center;
}

.ModalMyTaxApp .ant-btn {
    height: 40px;
    font-size: 16px;
    margin-top: 1rem;
}

.ModalMyTaxApp .ant-btn.btn-my-tax:active,
.ModalMyTaxApp .ant-btn.btn-my-tax:hover,
.ModalMyTaxApp .ant-btn.btn-my-tax:focus,
.ModalMyTaxApp .ant-btn.btn-my-tax {
    background-color: #0066B3;
    color: #FFFFFF;
}

.ModalMyTaxApp .ant-btn.btn-confirm {
    background-color: var(--green-color);
    color: #FFFFFF;
}

.ModalMyTaxApp .ant-btn img {
    margin-right: 0.5rem;
}
