.innPhotoDescription p {
    text-align: left;
}

.innPhotoDescription img {
    max-height: 400px;
    margin-bottom: 1rem;
    max-width: 100%;
}

form .ant-upload {
    width: 100%;
}

form .ant-form-item-control-input .ant-upload button {
    background-color: #E6F7FF;
    border-color: #1890FF;
    color: #096DD9;
}
form .ant-form-item-control-input .ant-upload button:hover:not(:disabled) {
    color: rgba(0, 0, 0, 0.85);
}
form .ant-form-item-control-input .ant-upload-list button {
    background-color: transparent !important;
    border-color: transparent !important;
}
