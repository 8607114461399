.exists-nfc {
    display: flex;
    align-items: center;
}
.exists-nfc span {
    font-size: 18px;
    margin-right: 1rem;
}
.exists-nfc button {
    color: rgba(0, 0, 0, 0.85) !important;
    background-color: rgba(0, 0, 0, 0.25) !important;
    border-radius: 100px !important;
}
.exists-nfc button.ant-switch-checked {
    border-color: #1890ff !important;
    background: #1890ff !important;
}