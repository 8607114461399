.ant-image-img {
    height: auto !important;
    max-height: 500px;
    width: auto !important;
    max-width: 700px;
}
.ant-alert-message .ant-typography.ant-typography-secondary {
    font-size: 15px;
}

.PartnerDocuments {
    width: 100%;
}

.PartnerDocuments .preview {
    width: 700px;
    height: 500px;
    background-color: white;
}

.PartnerDocuments .preview p {
    text-align: center;
    padding-top: 200px;
    color: gray;
}

.PartnerDocuments .preview img {
    object-fit: contain;
}
