.modalAuthUuid .header {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    text-align: center;
    margin-bottom: 0.8rem;
}

.modalAuthUuid .message {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    color: #828282;
}

.modalAuthUuid .footer {
    margin-top: 1rem;
    text-align: center;
}

.modalAuthUuid .ant-modal-content {
    border-radius: 16px;
}

.modalAuthUuid button {
    border-radius: 7px;
}

.modalAuthUuid .ant-btn-primary:active,
.modalAuthUuid .ant-btn-primary:hover,
.modalAuthUuid .ant-btn-primary:focus,
.modalAuthUuid .ant-btn-primary {
    background-color: var(--green-color);
}

.modalAuthUuid .ant-input:hover,
.modalAuthUuid .ant-input:focus,
.modalAuthUuid .ant-input-focused,
.modalAuthUuid .ant-btn-primary,
.modalAuthUuid button,
.modalAuthUuid .ant-btn-primary:active,
.modalAuthUuid .ant-btn-primary:focus,
.modalAuthUuid .ant-btn-primary:hover {
    border-color: var(--green-color);
}
.modalAuthUuid .ant-input[disabled],
.modalAuthUuid .ant-btn[disabled] {
    background: #f5f5f5;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.25);
}
