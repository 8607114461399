.filter-input {
    min-width: 280px;
    /*width: 272px;*/
}
.ant-divider-horizontal.dropdown-divider {
    margin: 4px 0
}
.dropdown-footer {
    padding: 8px;
}
.assets-selector-options {
    overflow-x: hidden;
    white-space: pre-wrap;
    text-overflow: ellipsis;
}

.assets-selector-options .ant-col {
    align-content: center;
}
