.image-preview .actions-buttons {
    display: flex;
    align-items: center;
}

.image-preview .actions-buttons .ant-col {
    margin-right: 1rem;
    margin-left: 1rem;
}

.image-preview .idViewImage {
    position: absolute;
}

.flipped {
    -webkit-transform: scaleX(-1) !important;
    transform: scaleX(-1) !important;
}