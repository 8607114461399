.bankAccept {

}
.bankAccept .ant-alert-content {
    text-align: left;
}

.bankAccept .bankAcceptForm {
    /*margin-top: 1rem;*/
}

.bankAccept .ant-btn-primary, .bankAccept .ant-btn-primary:hover {
    background-color: var(--green-color);
    border-color: var(--green-color);
}

.App .ProfileWrapper.bankAccept .ant-layout-content,
.App .ProfileWrapper.bankAccept.ant-layout {
    height: 100%;
    min-height: auto;
}
