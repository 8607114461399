.city-chain-store {
    display: flex;
}
.city-chain-store .ant-col {
    padding: 16px;
    height: 65px;
    display: flex;
    align-items: center;
}
.city-chain-store .ant-col:first-child {
    flex-grow: 1;
}
