.buttonChangePhone {
    text-align: center;
    padding-top: 8px;
}

.modalChangePhone {
}

.modalChangePhone h1 {
    text-align: center;
}

.modalChangePhone .message {
    text-align: center;
    color: #838383;
    margin-top: 8px;
}

.modalChangePhone button {
    margin-top: 1rem;
}

.modalChangePhone .ant-form-item.phone-btn {
    margin-bottom: 30px;
}
.modalChangePhone .ant-form-item.phone-input {
    margin-bottom: 0;
}

.modalChangePhone .ant-form-item-control-input input {
    /*border-radius: 7px;*/
}

.modalChangePhone .buttonSend, .modalChangePhone .buttonSend:hover {
    background-color: #E6F7FF;
    border-color: #1890FF !important;
    color: #1890FF;
    /*border-radius: 7px;*/
}

.modalChangePhone .ant-modal-content {
    border-radius: 16px;
}
