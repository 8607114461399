.legal-select-icon {
    display: flex;
    flex-direction: row;
    position: absolute;
    right: 0;
    top: -2px;
}

.legal-form-popup h3 {
    text-align: center;
}

.legal-form-popup .ant-radio-group {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 20px;
}

.legal-form-popup .ant-radio-group .ant-space-item {
    margin-bottom: 8px;
}

.legal-form-popup .ant-btn {
    width: 100%;
}

.legal-form-popup .ant-radio-wrapper {
    font-size: 16px;
}
.tax-system .ant-space.ant-space-horizontal,
.tax-system .ant-space.ant-space-horizontal .ant-space-item:first-child {
    width: 100%;
}
