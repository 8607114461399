.ProfileContractScreen .document {
    background-color: #fff;
    border: 1px solid #d9d9d9;
    padding: 4px 11px;
    border-radius: 3px;
    white-space: pre-line;
    text-align: start;
    margin: 0 20px 20px 20px;
}
.ProfileContractScreen .ant-page-header-heading-title {
    white-space: normal;
}
.ph-6 {
    padding-left: 20px;
    padding-right: 20px;
}
.pre-line {
    white-space: pre-line;
}
.ProfileContractScreen .links {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}