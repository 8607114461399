.modal-shopper-download .app-button {
    margin-bottom: 1rem;
    width: 100%;
    height: 50px !important;
}
.modal-shopper-download .app-button:last-child {
    margin-bottom: 0;
}
.modal-shopper-download .app-button .icon {
    margin-right: 0.5rem;
}
.modal-shopper-download .drawer-container .ant-spin-container {
    padding-top: 1rem;
}
