.MobileHeader.ant-layout-header {
    background-color: #f7f8fa;
    display: flex;
    align-content: center;
    justify-content: center;
    height: auto;
}

.MobileHeader .ant-image-img {
    max-height: 50px;
    max-width: 56vw;
    /*min-width: 250px;*/
    /*padding-top: 30px;*/
    /*width: 80%;*/
}
