.verification-status button {
    min-width: 155px;
    text-align: center;
}
.verification-status button.ant-btn.red {
    border-color: red;
    color: red;
}
.verification-status button.ant-btn.red.active {
    background-color: red;
    color: white;
}

.verification-status button.ant-btn.green {
    border-color: green;
    color: green;
}
.verification-status button.ant-btn.green.active {
    background-color: green;
    color: white;
}


