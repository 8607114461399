/*.App .ProfileWrapperV2 .Profile,*/
/*.App .ProfileWrapperV2 .ant-layout-content,*/
/*.App .ProfileWrapperV2.ant-layout {*/
/*    height: fit-content;*/
/*    min-height: 100%;*/
/*}*/


/*.App .ProfileWrapperV2.correction .ant-layout-content,*/
/*.App .ProfileWrapperV2.correction.ant-layout,*/
.App .ProfileWrapperV2.verified .ant-layout-content,
.App .ProfileWrapperV2.verified.ant-layout {
    height: 100%;
    min-height: 100%;
}

.App .ProfileWrapperV2.verified .ant-layout-content.Profile,
.App .ProfileWrapperV2.correction .ant-layout-content.Profile {
    height: 100%;
    min-height: auto;
}

.App .ProfileWrapperV2.checking .ant-layout-content {
    min-height: auto;
}

.App .ProfileWrapper.checking .ant-layout-content .success-step,
.App .ProfileWrapper.profileFilled .ant-layout-content .success-step {
    height: 100%;
    min-height: auto;
}
.App .ProfileWrapper.verified .ant-layout-content .success-step {
    height: calc(100% - 51px);
    min-height: auto;
}
.App .ProfileWrapper.checking .ant-layout-content.success-step {
    height: calc(100% - 51px);
    min-height: auto;
}

.App .ProfileWrapperV2.checking.ant-layout {
    height: 100%;
    min-height: 100%;
}

h4.ant-typography.title-green,
.ProfileWrapperV2 h4.ant-typography {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--green-color);
    text-align: left;
    padding: 16px 0px 8px 0px;
}

.flowV2 .ant-spin-nested-loading > div > .ant-spin {
    max-height: none !important;
    background-color: rgba(0, 0, 0, 0.45);
}

.ProfileWrapperV2 .ant-upload button.ant-btn {
    background-color: #E6F7FF;
    border-color: #1890FF;
    color: #096DD9;
}

.ProfileWrapperV2 .ant-form-item-control-input input {
    border-radius: 2px;
}

.ProfileWrapperV2 .ant-form-item-required::before {
    display: none !important;
}

.ProfileWrapperV2.ant-layout {
    padding: 10px 16px 0 !important;
}

.legal-form-popup .continue-button,
.legal-form-popup .continue-button:hover,
.legal-form-popup .continue-button:focus,
.legal-form-popup .continue-button:active,
.ProfileWrapperV2 .ant-form-item-control-input .continue-button {
    border-radius: 2px;
    border-color: var(--green-color);
    background-color: var(--green-color);
    /*height: 40px !important;*/
}
