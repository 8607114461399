.HelpDocModal {
    height: 100% !important;
    width: 100% !important;
    top: 0 !important;
    max-width: 100% !important;
    padding-bottom: 0 !important;
    margin: 0 !important;
}

.HelpDocModal .ant-modal-content {
    height: 100%;
    background-color: #262626;
    color: #BABABA;
}

.HelpDocModal .ant-modal-footer {
    border-top: 0;
}

.HelpDocModal .ant-modal-close {
    left: 0;
    right: auto;
    width: 100%;
    background-color: #262626;
}

.HelpDocModal .ant-modal-body {
    padding-top: 66px;
    padding-left: 16px;
    padding-right: 16px;
    height: calc(100% - 75px);
    overflow-y: auto;
}

.HelpDocModal .ant-modal-content .ant-typography {
    color: inherit;
}

.HelpDocModal .ant-modal-content h3 {
    color: #FFFFFF !important;
}

.HelpDocModal .img-doc {
    width: 100%;
    max-width: 600px;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}
/*.HelpDocModal .flex-column .btn-block {*/
/*    width: 100%;*/
/*    height: 50px;*/

/*}*/
/*.HelpDocModal .flex-column {*/
/*    min-height: max-content;*/
/*    height: calc(100% - 90px);*/
/*}*/
.HelpDocModal .relative {
    position: relative;
    width: 90vw;
    height: 100%;
}
.HelpDocModal .img-doc.sber {
    width: 120px;
    position: absolute;
    top: 118px;
    left: calc(50% - 60px);
    margin: 0;
}
.HelpDocModal .img-doc.alfa{
    width: 150px;
    position: absolute;
    left: calc(50% - 10px);
    margin: 0;
}
.HelpDocModal .img-doc.t {
    width: 150px;
    position: absolute;
    left: calc(50% - 140px);
    margin: 0;
}

.HelpDocModal button {
    /*margin-top: 1rem;*/
    width: 100%;
}

.HelpDocModal .inn-banks ul {
    padding-inline-start: 25px;
}

.info-link.ant-btn-link {
    padding-left: 0;
    margin: -10px 0 0 -20px;
}
