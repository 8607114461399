.ant-list-item input[type="number"]{
    width: 60px;
}
.title,
.sub-title {
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
}
.sub-title {
    margin-bottom: 25px;
}
.list {
    max-height: 400px;
    overflow-x: auto;
}
.list .ant-list-item-action {
    margin-left: 20px;
}
.fill-content {
    width: -webkit-fill-available;
}
.act-type-return {
    color: green !important;
}
.act-type-writeOff {
    color: red !important;
}
.asset-inventory-flags {
    background-color: #F5F5F5;
    padding: 5px;
}
.asset-status .ant-typography {
    cursor: pointer;
    color: #1890ff !important;
}