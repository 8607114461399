.ant-btn.button-link {
    padding: 0;
    height: auto;
    font-size: small;
}
.kind-edit {
    background: rgba(128, 128, 128, 0.1);
}

.kind-edit .padding-row {
    padding: 10px 0;
}

.ant-divider-horizontal.small {
    margin: 12px 0
}
.spin {
    width: 100%;
    margin-bottom: 0
}
.bg-changed {
    background: #fff2f0;
}
.bg-changed td {
    border-top: 1px solid #ffccc7 !important;
    border-bottom: 1px solid #ffccc7 !important;
}
.bg-changed + .bg-changed td {
    border-top: 0 !important;
}
.bg-changed td:last-child {
    /*font-weight: bold;*/
}

.partner-bank-data .ant-col {
    display: flex;
    align-items: center;
}