.ProfileFormWrapper {
    padding: 0 20px 20px;
}

.ProfileFormWrapper .ant-btn {
    padding: 0;
    border-radius: 2px;
}

.ProfileFormWrapper .ant-page-header {
    padding-left: 0;
}

.App .ProfileFormWrapper .ant-form {
    height: calc(100% - 51px);
    min-height: auto;
}

.ProfileFormWrapper .ant-page-header .ant-page-header-heading-title {
    font-size: 15px;
}

.ProfileFormWrapper .ant-form-item-explain {
    text-align: left;
}

.ProfileFormWrapper .info {
    text-align: left;
    margin-bottom: 1rem;
}

.ProfileFormWrapper .arrow-left span {
    color: var(--green-color);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

.ProfileFormWrapper .arrow-left img {
    margin-right: 0.5rem;
    margin-bottom: 3px;
}

.ProfileFormWrapper .flex-column {
    /*height: calc(100vh - 72px);*/
    height: 100%;
    align-content: space-between;
}

.ProfileFormWrapper .nalog-btn {
    background-color: #0066B3;
}
.ProfileFormWrapper .radio-group {
    width: 100%;
    text-align: left;
}
