.partners-stats {
    margin-top: 10px;
    min-width: 500px;
}
.partners-stats .ant-row, .partners-stats .ant-col {
    line-height: 1rem !important;
}
.partners-stats .ant-col {
    text-align: left;
}

.partners-stats-list {
    min-width: 700px;
}
.partners-stats-list .list {
    max-height: 500px;
    overflow-x: auto;
}
.partners-stats-list .list .iconTime {
    margin-right: 0.5rem;
}
.partners-stats-list .list .emptyIcon {
    margin-right: 0.5rem;
    display: inline-block;
    width: 14px;
    height: 14px;
}

.coordinator-stats-list {
    min-width: 900px !important;
}