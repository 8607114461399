.autocomplete-filter {
  width: 300px;
}
.autocomplete-filter .checkbox-group {
  width: 100%;
  max-height: 265px;
  overflow-y: auto;
}
.autocomplete-filter .checkbox-group .ant-checkbox-wrapper {
  padding: 0 10px;
  display: flex;
  margin: 0;
  line-height: 32px;
  height: 32px;
}
.autocomplete-filter .checkbox-group .ant-checkbox-wrapper:hover {
  background-color: #f5f5f5;
}

.autocomplete-filter .ant-select {
  width: 100%;
}
.autocomplete-filter .tooltip-container {
  width: 100%;
  padding: 10px 10px 15px;
}
