.bank-accept-step .ant-btn:not(.ant-btn-success) {
    /*width: 100%;*/
    /*margin-top: 1rem;*/
    /*height: 40px;*/
    /*font-size: 16px;*/
    background: none !important;
    border-color: #d9d9d9 !important;
}

.bank-accept-step .ant-btn-success {
    color: var(--green-color) !important;
    color: #FFFFFF !important;
}
/*.bank-accept-step .flex-column {*/
/*    !*height: calc(100vh - 10px);*!*/
/*    height: 100%;*/
/*    margin-bottom: -13px;*/
/*}*/
