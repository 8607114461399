.Users .clickable-cell-table {
    z-index: 50;
}
.Users .ant-table-tbody .ant-table-cell-ellipsis {
   position: inherit;
}
.Users .clickable-cell-table .ant-collapse {
    border: none;
    background-color: inherit;
}
.Users .clickable-cell-table .ant-collapse > .ant-collapse-item {
    border: none;
}
.Users .clickable-cell-table .ant-collapse-content.ant-collapse-content-active {
    max-height: 400px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    position: absolute;
    z-index: 55;
    background-color: white;
}
.Users .ant-collapse-content.ant-collapse-content-active {
    max-height: 400px;
    overflow: auto;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.Users .clickable-cell-table .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 0 0 20px;
}
.Users .clickable-cell-table .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    left: 0;
}
#editUser .ant-form-item {
    margin-bottom: 18px;
}

.user-subscribes-tree .ant-tree-list-holder-inner {
    padding-bottom: 50px;
}
.user-city-tree .ant-tree-list-holder-inner {
    padding-bottom: 70px;
}