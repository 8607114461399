.photo-doc {
    text-align: left;
    padding: 1rem;
}

.photo-doc .title-good {
    color: #3aaa35;
}

.photo-doc .title-bad {
    color: #c9211e;
}

.photo-doc .title-good span, .photo-doc .title-bad span {
    margin-right: 0.5rem;
}

.photo-doc .img-doc {
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.photo-doc-alert {
    padding-left: 0;
}

.photo-doc-list {
    list-style-type: auto;
}
