.pdf-preview {
    width: max-content;
    max-width: 100%;
    max-height: 100%;
    top: 50px !important;
}
.pdf-preview .ant-modal-body {
    max-width: 100%;
    max-height: 100%;
}
.pdf-preview .ant-modal-body .react-pdf__Document {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: auto;
}
.pdf-preview .ant-modal-body .react-pdf__Page__canvas {
    margin: 0 auto;
}
.pdf-preview .scroll-container {
    width: 100%;
    height: 550px;
}

.pdf-preview .actions-buttons {
    display: flex;
    justify-content: center;
}

.pdf-preview .actions-buttons > div {
    margin-left: 1rem;
    margin-right: 1rem;
}

.rotated-0 {
    transform: rotate(0);
}
.rotated-90 {
    transform: rotate(90deg);
}
.rotated-180 {
    transform: rotate(180deg);
}
.rotated-270 {
    transform: rotate(270deg);
}
